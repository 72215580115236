import { Component } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { CustomerCarouselComponent } from 'src/app/core/shared/customer-carousel/customer-carousel.component';
import { SimsButtonsComponent } from 'src/app/core/shared/sims-buttons/sims-buttons.component';
import { PlanCardComponent } from 'src/app/core/shared/plan-card/plan-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Plan, SimPlan, GetSimPlansResult, GetActivationPlanResult, GetReloadPlanResult,
} from 'src/app/services/models/market-plans.model';
import { MarketPlansService } from 'src/app/services/market-plans/market-plans.service';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivateSimService } from 'src/app/services/activate-sim/activate-sim.service';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { FormGroup } from '@angular/forms';
import { ReloadEsimService } from '../../services/reload-esim/reload-esim.service';
import { SlidersPipe } from 'src/app/pipes/sliders.pipe';
import { PopUpComponent } from 'src/app/core/shared/pop-up/pop-up.component';

@Component({
  selector: 'app-plan-page',
  standalone: true,
  imports: [
    CommonModule,
    CustomerCarouselComponent,
    SimsButtonsComponent,
    PlanCardComponent,
    TranslocoModule,
    FlagsPipe,
    SlidersPipe,
    PopUpComponent
  ],
  templateUrl: './plan-page.component.html',
  styleUrls: ['./plan-page.component.scss'],
})
export class PlanPageComponent {
  activeButton: string = this.route.snapshot.params['type'] || 'esim';
  selectedCountry: string = '';

  showPopUp: boolean = false;
  popUpMessage: string = '';

  simButtonVisible: boolean = true;
  esimButtonVisible: boolean = true;
  dataButtonVisible: boolean = true;
  skeletonBoxes: number[] = [1, 2, 3];

  filteredPlans: SimPlan[] = [];
  marketSelected: GetSimPlansResult | null = null;
  plans: Plan[] = [];

  reloadEsimForm = this.submitOrderService.submitForm.get(
    'reloadForm'
  ) as FormGroup;

  activateForm = this.submitOrderService.submitForm.get(
    'activateForm'
  ) as FormGroup;

  constructor(
    private route: ActivatedRoute,
    private marketPlansService: MarketPlansService,
    private activateSimService: ActivateSimService,
    private reloadEsimService: ReloadEsimService,
    private router: Router,
    private scroller: ViewportScroller,
    private submitOrderService: SubmitOrderService
  ) {}

  ngOnInit(): void {
    this.submitOrderService.clearOrderData();

    const currentRoute = this.router.url;
    this.selectedCountry = this.route.snapshot.params['market'];

    if (currentRoute.includes('/activate-sim/')) {
      // const qpm = this.route.queryParams.subscribe((e: any)=> { })
      this.route.queryParamMap.forEach((e: any) => {
        const phoneNumber = e.params.phoneNumber;
        if (phoneNumber) {
          this.validateActivationPhone(phoneNumber);}
          phoneNumber.charAt(0) === '8' 
          ? this.activateForm.get('serialNumber').setValue(phoneNumber)
          : this.activateForm.get('phoneNumber').setValue(phoneNumber);
      });
    } else if (currentRoute.includes('/reload-esim/')) {
      this.route.queryParamMap.forEach((e: any) => {
        const phoneNumber = e.params.phoneNumber;
        if (phoneNumber) {
          this.validateReloadPhone(phoneNumber);
          this.reloadEsimForm.get('phoneNumber').setValue(phoneNumber);
        }
      });
    } else {
      this.getMarketSelected(this.selectedCountry);
    }
  }

  ngAfterViewInit(): void {
    this.scroller.scrollToPosition([0, 0]);
  }

  async getMarketSelected(marketName: string) {
    try {
    const data =
      await this.marketPlansService.getMarketPlans<GetSimPlansResult>(
        marketName
      );

      this.marketSelected = data.result;
      this.plans = this.marketSelected.plans;
      this.updateSimTypeFilters(this.activeButton);
    } catch (error) {
      console.warn(error);
    }
  }

  async validateActivationPhone(phoneNumber: string) {
    const res =
      await this.activateSimService.validateActivationPhone<GetActivationPlanResult>(
        phoneNumber
      );
    this.plans = res.result.plans;
    this.selectedCountry = res.result.groupName;
    this.updateSimTypeFilters(this.activeButton);
    try {
    } catch (error) {}
  }

  async validateReloadPhone(phoneNumber: string) {
    const res =
      await this.reloadEsimService.validateEsimPhone<GetReloadPlanResult>(
        phoneNumber
      );

    try {
      if (res.isSuccessful) {
        this.reloadEsimService.setReloadTicket(res.result.ticket);
        this.plans = res.result.plans;
        this.selectedCountry = res.result.groupName;
        this.updateSimTypeFilters(this.activeButton);
      }
    } catch (error) {}
  }

  // obtain the specific plans for the type of sim
  updateSimTypeFilters(simType: string) {
    const matchingPlan = this.plans.find((p) => p.simTypeName === simType);

    if (this.plans && matchingPlan) {
      this.filterPlans(matchingPlan.simPlans)
    } else if (this.plans.length > 0) {
      this.activeButton = this.plans[0].simTypeName;
      this.filterPlans(this.plans[0].simPlans)
    } else {
      console.log(`Invalid simType or plans not found.`);
      this.filteredPlans = [];
    }
  }

  // Filter plans by BrandID 1 and low Price to high Price
  filterPlans(allPlans: SimPlan[]) {
    this.filteredPlans = allPlans
      .filter((p) => p.brandId === 1)
      .sort((planA, planB) => {
        return planA.price * planA.quantity - planB.price * planB.quantity;
    });
  }

  changeActiveButton(buttonType: string): void {
    const isActivation = this.router.url.includes('/activate-sim/');
    const isReload = this.router.url.includes('/reload-esim/');

    this.activeButton = buttonType;
    this.updateSimTypeFilters(buttonType);
    this.router.navigate([
      isActivation
        ? '/activate-sim'
        : isReload
        ? '/reload-esim'
        : '/get-your-sim',
      this.selectedCountry,
      this.activeButton,
    ]);
  }

  openPopUp(country: string) {
    this.showPopUp = true
    this.popUpMessage = `${country}-countries-${this.activeButton}`
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  isLargeSizeGroup(groupName: string): boolean {
    const largeSizeGroups = ['Hamaagal', 'Momentum', 'Honeymoon', 'Itrek', 'NCSY'];
    return largeSizeGroups.includes(groupName);
  }

}
