<main id="getSimPage" class="py-10 px-6 mx-auto">

  <div class="flex flex-col mlg:flex-row justify-center gap-4">
    <section class="bg-greyLight rounded-3xl p-8 sm:p-12 shadow-box w-full lg:w-1/2 max-w-[620px] mx-auto">
      <img src="assets/images/logo.webp" alt="logo globusim" class="w-24 sm:w-32 mx-auto sm:pb-8">

      <h2 class="font-bold text-orange text-center text-xl sm:text-3xl pt-6 border-b" [transloco]="'pickup.store_name_' + selectedStore.locationID"></h2>
      <article class="py-8 flex flex-col items-center">
        <div class="pb-4 sm:pb-8 ">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.open-hours"></h4>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day1_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day2_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day3_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day4_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day5_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day6_hours_' + selectedStore.locationID"></p>
            <p class="flex flex-col text-center text-sm sm:text-base " [transloco]="'pickup.store_day7_hours_' + selectedStore.locationID"></p>

        </div>
        <div class="pb-4 sm:pb-8 ">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.address"></h4>
          <p class="text-center text-sm sm:text-base" [transloco]="'pickup.store_address_' + selectedStore.locationID"></p>
        </div>
        <div class="pb-4 sm:pb-8 ">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.phone"></h4>
            <p class="text-center text-sm sm:text-base" [transloco]="'pickup.store_phone_' + selectedStore.locationID"></p>
        </div>
        <div class="">
            <p class="text-center text-sm sm:text-base" [transloco]="'pickup.store_comments_' + selectedStore.locationID"></p>
        </div>
      </article>
    </section>
    <section class="w-fit mx-auto">
      <img src="assets/images/icons/pick-up.svg" alt="" class="w-[620px] h-[620px]">
    </section>
  </div>
</main>