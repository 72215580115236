import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupStoreLocationsService } from 'src/app/services/pickup-store-locations/pickup-store-locations.service';
import { TranslocoModule } from '@ngneat/transloco';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';

@Component({
  selector: 'app-pick-up-stores-page',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './pick-up-stores-page.component.html',
  styleUrls: ['./pick-up-stores-page.component.scss']
})
export class PickUpStoresPageComponent {
  selectedStore: any = this.pickupStoreService.getStore()
  globalForm = this.submitOrderService.submitForm;
  
  constructor(private pickupStoreService: PickupStoreLocationsService, private submitOrderService: SubmitOrderService,) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.globalForm.reset()
    this.submitOrderService.clearOrderData()
  }
}
