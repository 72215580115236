<main class="py-10 px-6 md:px-16 mlg:px-36">
  <div *ngIf="selectedPlan">
    <section
      class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
      <h2 class="text-center text-4xl font-semibold" transloco="thank-you.title"></h2>
    </section>

    <div class="flex flex-col items-center animate-fade-up">
      <span class="text-grey text-sm" transloco="thank-you.confirmation-id"></span><strong>#{{confirmationID}}</strong>
      <ng-container *ngIf="envelope" >
        <span class="text-grey text-sm" transloco="thank-you.envelope-confirmation"></span><strong>&nbsp;{{ envelope }}</strong>
        <span class="text-grey text-sm" transloco="thank-you.envelope-info"></span>
      </ng-container>
      
      
      <div class="border border-greyLight rounded-2xl shadow-xl w-full px-8 max-w-[580px]">

        <section class="sm:px-6 py-6">
          <article class="flex flex-col sm:flex-row">

            <div class="flex flex-col items-center">
              <img [src]="selectedMarket.toLowerCase() | appSquares" alt="country image"
                class="w-[5rem] h-[5rem] sm:w-28 sm:h-28 rounded-3xl">
            </div>
            <div class="flex flex-1 flex-col sm:pl-4 rtl:sm:pr-4 pt-4 sm:pt-0">
              <h6 class="text-grey font-medium">
                <span transloco="checkout.summary.plan"></span>
                <span class="text-black font-semibold">{{ selectedPlan?.planNameAdmin.replaceAll('_', ' ') }}</span>
              </h6>
              <ul class="text-grey font-medium text-sm divide-y border-y">
                <li class="flex py-1">
                  <p class="flex flex-1" transloco="checkout.choose-date.sim-quantity"></p><strong class="text-black">{{
                    dateForm.get('simQuantity').value }} </strong>
                </li>
                <li class="flex py-1">
                  <p class="flex flex-1" transloco="checkout.summary.period"></p><strong class="text-black">{{
                    dateForm.get('simsPerPeriod').value }}</strong>
                </li>
                <li class="flex py-1">
                  <p class="flex flex-1" transloco="checkout.summary.dates"></p><strong class="text-black text-xs">{{
                    dateForm.get('beginDate').value }} - {{ dateForm.get('endDate').value }} </strong>
                </li>
              </ul>
            </div>
          </article>

          <article class="pt-4 pb-2 border-b border-greyLight">
            <ul>
              <li class="flex py-1">
                <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.subtotal"></p><strong
                  class="text-sm">${{selectedPlan?.price}}</strong>
              </li>
              <li class="flex py-1">
                <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.shipping"></p>
                <span class="text-xs mx-4">{{minShippingDate.replaceAll('-', '/')}} - {{maxShippingDate.replaceAll('-',
                  '/')}}</span>
                <strong class="text-sm">${{ shippingPrice || 0 }} </strong>
              </li>
              <li [hidden]="couponPrice === '0'" class="flex py-1">
                <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.coupon"></p><strong
                  class="text-sm">-
                  ${{ couponPrice }}</strong>
              </li>
              <hr class="pb-2 mt-2">
              <li *ngIf="simType === 'long-term' && this.dateForm.get('simsPerPeriod').value > 1" class="flex py-1">
                <p class="flex flex-col sm:flex-row flex-1 sm:items-center text-base">
                  <strong transloco="checkout.summary.long-term-today-payment"></strong>
                  <span class="text-xs sm:px-4" transloco="checkout.summary.long-term-for-three-months"></span>
                </p>
                <strong class="text-base">${{ selectedPlan.price * 3 * dateForm.get('simQuantity').value }}</strong>
              </li>
              <li class="flex py-1">
                <p class="flex flex-col sm:flex-row sm:items-center flex-1 text-xl">
                  <strong transloco="checkout.summary.total"></strong>
                  <span class="text-xs sm:px-4 " transloco="checkout.summary.price-include-taxes"></span>
                </p>
                <strong class="text-xl">${{ totalPrice() }}</strong>
              </li>
            </ul>
          </article>
        </section>
      </div>
      <div class="py-8" *ngIf="simType === 'sim' && !envelope">
        <h3 class="text-2xl text-orange font-semibold" transloco="thank-you.whats-next"></h3>
        <p class="text-sm text-grey py-4" transloco="thank-you.whats-next-desc"></p>
        <span [innerHTML]="'thank-you.pdf-download-' + selectedPlan.carrierId | transloco"></span>
        <p class="text-xs text-grey py-4" transloco="thank-you.whats-next-mail"></p>
      </div>

      <div class="py-8 max-w-[580px]">
        <h4 class="text-center text-xl font-semibold pb-4" transloco="thank-you.next-steps"></h4>
        <span [innerHTML]="'thank-you.next-steps-desc-' + simType | transloco"></span>
      </div>
    </div>
  </div>
  <div *ngIf="!selectedPlan">
    <app-spinner></app-spinner>
  </div>
</main>

<script>
  let mail = $('#email_customer').val()

  LeadDyno.key = "0c4179a11316a1d1eadf5e93186b2aaa823370d2";
  LeadDyno.recordPurchase(mail, {
      purchase_amount: this.totalPrice()
  });
</script>