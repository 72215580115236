<main class="instructions py-10 px-6 md:px-16 mlg:px-36">
  <section class="flex flex-col items-center py-8">
    <h1 class="text-4xl font-semibold" transloco="instructions.title.iphone"></h1>
    <span class="text-xl" transloco="instructions.subtitle"></span>
  </section>

  <section class="steps" [innerHTML]="'instructions.steps.activate-iphone' | transloco">
    <!-- <h2>Activate your eSIM</h2>
    <ol>
      <li>Open the Camera app and scan your QR code.</li>
      <li>When the Cellular Plan is Detected and a notification appears, tap it.</li>
      <li>Tap Continue, at the bottom of the screen.</li>
      <li>Tap Add Cellular Plan.</li>
    </ol>

    <p>For manual registation for IOS 15 and down:</p>
    <ol>
      <li>Go to Settings.</li>
      <li>Tap either Cellular or Mobile Data.</li>
      <li>Tap Add Cellular Plan.</li>
      <li>If your Tap Enter Details Manually, at the bottom of your iPhone screen.</li>
    </ol>

    <p>For manual registation forlOS 16:</p>
    <ol>
      <li>Go to Settings.</li>
      <li>Tap either Cellular or Mobile Data.</li>
      <li>Tap Add eSIM.</li>
      <li>Tap scan your QR code.</li>
      <li>Tap Enter Details Manually, at the bottom of your iPhone screen.</li>
    </ol> -->
  </section>
  <section [innerHTML]="'instructions.steps.access-iphone' | transloco">
    <!-- <h2>Access Data</h2>
    <div>
      <span>- To choose your data line, go to Settings > Cellular >Cellular (or Mobile) data. You can continue to use apps to make VoIP calls or send messages while you're traveling.</span>
      <span>- You can also turn data roaming on and oFF on your home line in Settings > Cellular n Cellular Data Options. Carrier Fees might apply.</span>
      <span>* To learn more about eSIM on iPhone go to: <a href="https://support.apple.com/en-us/HT212780" target="_blank" rel="noopener noreferrer">https://support.apple.com/en-us/HT212780</a> </span>
    </div> -->
  </section>
  <section [innerHTML]="'instructions.steps.setup-iphone' | transloco">
    <!-- <h2>Update your APN settings</h2>
    <p>You can view your APN settings in one of the following locations:</p>
    <ul>
      <li>- Settings > Cellular > Cellular Data Options > Cellular Network</li>
      <li>- Settings > Mobile Data > Mobile Data Options > Mobile Data Network</li>
    </ul>
    <p>If your device doesn't have either option, contact your carrier. To change your settings, tap each field and enter:</p>
    <ul>
      <li>- Under "cellular data": APN = fastaccess</li>
      <li>- Under "LTE setup": APN = fastaccess</li>
    </ul>
      <p>The settings should save automatically.</p>

      <div>
        <p>If you update iOS it will reset any changes made to your APN settings.</p>
        <p>If you edit your APN settings before updating iOS, your settings will reset to the default values.</p>
        <p>You may need to re-enter your settings after an iOS update.</p>
      </div> -->
  </section>

  <section [innerHTML]="'instructions.note-iphone' | transloco">
    <!-- <strong>Note:</strong>
    <p>
      Please turn OFF your original physical sim card line, iPhone it's in settings located under "cellular", so you won't have any Roaming fees charges with your original provider.
    </p> -->
  </section>

  <button>
    <span routerLink="/instructions-esim-android" transloco="instructions.link-to-android">Link to Android instructions</span>
  </button>
</main>