<section class="flex flex-col mlg:flex-row w-full py-10 px-10 md:px-20 mlg:px-28 lg:px-40">
  <article
    class="text-center mlg:text-start flex flex-col w-full mlg:w-1/3 pb-8 mlg:max-w-[420px] scroll-driven-show">
    <h2 transloco="home.where-travel-title" class="text-4xl sm:text-[2.875rem] font-semibold leading-[3.5rem]"></h2>
    <span transloco="home.where-travel-subtitle" class="py-8 text-grey"></span>
    <div class="hidden mlg:flex">
      <app-destinations-buttons></app-destinations-buttons>
    </div>
  </article>

  <article class="w-full" id="scroll-driven-market-cards">
    <div class="grid grid-cols-1 smd:grid-cols-2 xl:grid-cols-3 gap-5 md:px-12 xl:gap-8"
      *ngIf="countries.length === 0">
      <ng-container *ngFor="let box of skeletonBoxes">
        <div class="bg-greyLight animate-pulse w-full md:w-[280px] h-[110px] rounded-xl"></div>
      </ng-container>

    </div>
    <div class="grid grid-cols-1 smd:grid-cols-2 xl:grid-cols-3 gap-5 md:px-12 ">
      <ng-container *ngFor="let country of countries">
        <app-markets-card [country]="country"></app-markets-card>
      </ng-container>
    </div>
  </article>

  <div class="mlg:hidden pt-12 mx-auto">
    <app-destinations-buttons></app-destinations-buttons>
  </div>
</section>