<section class="w-full py-20 px-6 md:px-20 mlg:px-28 lg:px-40 scroll-driven-show">
  <article class="flex flex-col-reverse lg:flex-row text-center lg:text-start rounded-3xl p-3 sm:p-8 gradient">
    <div class="w-full lg:w-1/2 p-2"> 
      <div *ngIf="showVideo" class="embed-responsive embed-responsive-4by3 relative w-full overflow-hidden rounded-3xl"
        style="padding-top: 75%">
        <iframe title="globusim video" class="embed-responsive-item absolute bottom-0 left-0 right-0 top-0 h-full w-full aspect-video"
          src="https://www.youtube.com/embed/37zfrHSTnFA?autoplay=1" allowfullscreen="" data-gtm-yt-inspected-2340190_699="true"
          id="240632615"></iframe>
      </div>
      <img *ngIf="!showVideo" src="assets/images/backgrounds/video-image.webp" alt="video image desktop" class="hidden sm:block w-full h-full rounded-2xl" (click)="loadVideo()" loading="lazy">
      <img *ngIf="!showVideo" src="assets/images/backgrounds/video-image-mobile.webp" alt="video image mobile" class="sm:hidden w-full h-full rounded-2xl" (click)="loadVideo()" loading="lazy">
    </div>
    <div class="flex flex-col my-auto w-full lg:w-1/2 text-white px-8">
      <h2 class="text-4xl pt-4" transloco="home.how-to-use-title"></h2>
      <p class="text-3xl pb-4 font-semibold" transloco="home.how-to-use-subtitle"></p>
      <p class="" transloco="home.how-to-use-description"></p>
    </div>
  </article>
</section>